import {
  IoCheckmark,
  IoEllipsisHorizontalCircle,
  IoWarning,
} from "react-icons/io5";
import { defineMessage } from "react-intl";

export const CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING = "pending";
export const CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED = "published";
export const CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED = "moderated";

export const CARTS_BOOKINGS_REVIEW_STATES = {
  [CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING]: {
    id: CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    icon: IoEllipsisHorizontalCircle,
  },
  [CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED]: {
    id: CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED,
    colors: ["green.600", "green.300"],
    icon: IoCheckmark,
  },
  [CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED]: {
    id: CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED,
    colors: ["purple.600", "purple.300"],
    icon: IoWarning,
  },
};

export const CARTS_BOOKING_REVIEW_STATE_LIST = Object.values(
  CARTS_BOOKINGS_REVIEW_STATES,
);

export const cartsBookingReviewStatesMessage = defineMessage({
  defaultMessage: `{state, select,
    pending {En attente}
    published {Publié}
    moderated {Modéré}
    other {{state}}
  }`,
});

export const CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_PENDING = "pending";
export const CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_ACCEPTED = "accepted";
export const CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED = "rejected";
export const CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_NO_RESPONSE = "no_response";

export const CARTS_BOOKING_REVIEW_OWNER_STATES = {
  [CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_PENDING]: {
    id: CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_PENDING,
  },
  [CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_ACCEPTED]: {
    id: CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_ACCEPTED,
  },
  [CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED]: {
    id: CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED,
  },
  [CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_NO_RESPONSE]: {
    id: CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_NO_RESPONSE,
  },
};

export const CARTS_BOOKING_REVIEW_OWNER_STATE_LIST = Object.values(
  CARTS_BOOKING_REVIEW_OWNER_STATES,
);

export const cartsBookingReviewOwnerStatesMessage = defineMessage({
  defaultMessage: `{state, select,
    pending {En attente}
    accepted {Avis accepté}
    rejected {Avis refusé}
    no_response {Sans réponse}
    other {{state}}
  }`,
});

export const CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES = {
  [CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING]: {
    id: CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    icon: IoEllipsisHorizontalCircle,
  },
  [CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED]: {
    id: CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED,
    colors: ["purple.600", "purple.300"],
    icon: IoWarning,
  },
  [CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED]: {
    id: CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED,
    colors: ["green.600", "green.300"],
    icon: IoCheckmark,
  },
  [CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED]: {
    id: CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED,
    colors: ["purple.600", "purple.300"],
    icon: IoWarning,
  },
};

export const CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES_LIST =
  Object.values(CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES);

export const CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES_LIST_ADMIN =
  Object.values(CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES).sort(
    (a, b) => {
      if (a.id === CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED) {
        return -1;
      }

      return 1;
    },
  );

export const cartsBookingReviewStatesWithVirtualStatesPluralMessage =
  defineMessage({
    defaultMessage: `{state, select,
    pending {En attente}
    rejected {En attente de modération}
    published {Publiés}
    moderated {Modérés}
    other {{state}}
  }`,
  });

export const cartsBookingReviewStatesWithVirtualStatesMessage = defineMessage({
  defaultMessage: `{state, select,
    pending {En attente}
    rejected {En attente de modération}
    published {Publié}
    moderated {Modéré}
    other {{state}}
  }`,
});

export const CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CHECKIN_ASC =
  "checkin_asc";
export const CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CHECKIN_DESC =
  "checkin_desc";
export const CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CREATED_ASC =
  "created_asc";
export const CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CREATED_DESC =
  "created_desc";

export const cartsBookingsReviewSortMessage = defineMessage({
  defaultMessage: `{sort, select,
    checkin_asc {Date d'arrivée croissante}
    checkin_desc {Date d'arrivée décroissante}
    created_asc {Date de création croissante}
    created_desc {Date de création décroissante}
    other {{sort}}
  }`,
});

export const CARTS_BOOKINGS_REVIEW_SEARCH_SORTS = {
  [CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CHECKIN_ASC]: {
    id: CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CHECKIN_ASC,
  },
  [CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CHECKIN_DESC]: {
    id: CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CHECKIN_DESC,
  },
  [CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CREATED_ASC]: {
    id: CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CREATED_ASC,
  },
  [CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CREATED_DESC]: {
    id: CARTS_BOOKINGS_REVIEW_SEARCH_SORT_VALUE_CREATED_DESC,
  },
};

export const CARTS_BOOKINGS_REVIEW_SEARCH_SORTS_LIST = Object.values(
  CARTS_BOOKINGS_REVIEW_SEARCH_SORTS,
);

// Nombre de jours maximum ou le propriétaire peut modifier sa réponse (à partir de owner_response_at)
export const CART_BOOKING_REVIEW_UPDATE_OWNER_MAX_DAYS = 180;
